Vue.component('agreement-checkbox', {
    props: ['updateUrl', 'agreement', 'hiddenAgreementsJson', 'allAgreements'],
    data: function () {
        return {
            hiddenAgreements: JSON.parse(this.hiddenAgreementsJson || '[]')
        }
    },
    methods: {
        change() {
            // Try to find the agreement this checkbox represents in the list of hidden agreements
            let index = this.hiddenAgreements.indexOf(this.agreement.id);

            // If we found it, remove it from the list, otherwise, add it to the list
            if (index > -1) {
                this.hiddenAgreements.splice(index, 1);
            } else {
                this.hiddenAgreements.push(this.agreement.id);
            }
            API.PUT(this.updateUrl, {'agreement': this.agreement.id, 'checked': this.checked}).then(response => {
                this.$emit('update', response)
            });
        }
    },
    computed: {
        checked() {
            return !this.hiddenAgreements.includes(this.agreement.id);
        }
    }
});
