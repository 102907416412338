Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById("product-summary")) {
        Global.components.push(
            new Vue({
                el: '#product-summary',
                store,
                data: {
                    isLoading: false,
                },
                computed: {
                    pointerSelected: {
                        get: function () {
                            return this.$store.getters.pointerSelected
                        },
                        set: function (newValue) {
                            this.$store.dispatch('setPointerSelected', newValue)
                        }
                    },
                    showSavePopup: {
                        get: function () {
                            return this.$store.getters.showSavePopup
                        },
                        set: function (newValue) {
                            this.$store.dispatch('setShowSavePopup', newValue)
                        }
                    },
                    saveDataUrl: {
                        get: function () {
                            return this.$store.getters.saveDataUrl
                        },
                        set: function (newValue) {
                            this.$store.dispatch('setSaveDataUrl', newValue)
                        }
                    },
                    saveDataType: {
                        get: function () {
                            return this.$store.getters.saveDataType
                        },
                        set: function (newValue) {
                            this.$store.dispatch('setSaveDataType', newValue)
                        }
                    }
                },
                mounted: function () {
                },
                methods: {
                    download(image, title) {
                        axios({
                            url: 'dashboard/product/image?url=' + image,
                            method: 'GET',
                            responseType: 'blob',
                            crossDomain: true
                        }).then((response) => {
                            const url = window.URL
                                .createObjectURL(new Blob([response.data]));
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', title + '.jpg');
                            document.body.appendChild(link);
                            link.click();
                        })
                    },
                    redirectToProduct(event) {
                        this.isLoading = true;
                        window.location = this.$el.attributes['product-url'].value.replace(':id', event.target.value);
                    }
                },
                created: function () {
                },
                destroyed: function () {
                }
            })
        )
    }
});
