Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById("product-config-step-4")) {
        Global.components.push(
            new Vue({
                el: document.getElementById("product-config-step-4"),
                store,
                data: function () {
                    return {
                        submitUrl: window.location.origin + '/dashboard/configurator',
                        loadingResults: false,
                        showFilters: true,
                        searchQuery: '',
                        filters: [],
                        filterValues: [],
                        products: [],
                        rawResponse: null,
                        isAccountManager: document.getElementById("product-config-step-4").dataset.isAccountManager,
                        perPage: 20,
                        perPageOptions: [20, 50, 100, 200],
                        showLocation: true,
                    }
                },
                computed: {
                    configurator: {
                        get() {
                            return this.$store.state.configurator;
                        },
                        set: function (value) {
                            this.$store.dispatch('setConfigurator', value)
                        }
                    },
                    configuratorProductRemoved: {
                        get() {
                            return this.$store.state.configuratorProductRemoved;
                        },
                        set: function (value) {
                            this.$store.dispatch('setConfiguratorProductRemoved', value)
                        }
                    },
                    filtersUrl() {
                        let url = window.location.origin + '/dashboard/configurator/' + this.configurator.id + '/filters';
                        url = Global.updateQueryStringParameter(url, 'type', 'product');

                        // Filter from step 3
                        if(this.configurator.hasOwnProperty('preset') && this.configurator.preset.hasOwnProperty('filter') && this.configurator.preset.filter.length > 0) {
                            this.configurator.preset.filter.forEach((item) => {
                                url = Global.updateQueryStringParameter(url, 'filter[][' + item.filter + ']', item['value']);
                            })
                        }

                        return url;
                    },
                    productsUrl() {
                        let url = window.location.origin + '/dashboard/configurator/' + this.configurator.id + '/products';
                        url = Global.updateQueryStringParameter(url, 'type', 'product');
                        url = Global.updateQueryStringParameter(url, 'query', this.searchQuery);
                        url = Global.updateQueryStringParameter(url, 'per_page', this.perPage);

                        // Filter from step 3
                        if(this.configurator.hasOwnProperty('preset') && this.configurator.preset.hasOwnProperty('filter') && this.configurator.preset.filter.length > 0) {
                            this.configurator.preset.filter.forEach((item) => {
                                url = Global.updateQueryStringParameter(url, 'filter[][' + item.filter + ']', item['value']);
                            })
                        }

                        // Filter form the active view
                        this.filterValues.forEach(filter => {
                            if (typeof filter == 'object' && typeof filter.min != 'undefined') {
                                url = Global.updateQueryStringParameter(url, 'filter[' + filter.id + '][min]', filter.min);
                                url = Global.updateQueryStringParameter(url, 'filter[' + filter.id + '][max]', filter.max);
                            } else if(Array.isArray(filter)) {
                                url = Global.updateQueryStringParameter(url, 'filter[][' + filter[0] + ']', filter[1]);
                            }
                        });

                        return url;
                    },
                    nextStepAllowed() {
                        return this.configurator.step === 4 &&
                            (
                                this.isAccountManager && this.configurator.places !== undefined && this.configurator.places.available < this.configurator.places.total
                                ||
                                this.configurator.places !== undefined && this.configurator.places.available === 0
                            );
                    },
                    messageText: {
                        get: function () {
                            return this.$store.getters.messageText
                        },
                        set: function (newValue) {
                            this.$store.dispatch('setMessageText', newValue)
                        }
                    }
                },
                watch: {
                    configuratorProductRemoved(value) {
                        if (value) {
                            this.fetchProducts();
                            this.configuratorProductRemoved = false;
                        }
                    },
                    configurator: {
                        handler(newValue, oldValue) {
                            // this.showLocation = this.checkLocation();
                            // this.checkIfSubmitFormStepAllowed()

                            if (newValue.step > oldValue.step && newValue.id) {
                                window.scrollTo(0, 0);
                                this.fetchProducts();
                                this.getFilters();
                            }
                        },
                        deep: true
                    }
                },
                mounted: function () {
                    if (this.nextStepAllowed) {
                        this.showFilters = false;
                    }

                    this.getFilters();
                },
                methods: {
                    checkIfSubmitFormStepAllowed() {
                        let isAllowed = true;

                        this.submitFormStepAllowed = isAllowed;

                        return isAllowed;
                    },
                    checkLocation() {
                        if(!this.configurator) return true;
                        if(!this.configurator.preset) return true;
                        if(!this.configurator.preset.filter) return true;

                        // Check if filter beplanting (7) == kunstbeplanting (32)
                        let show = true;
                        this.configurator.preset.filter.forEach((filter) =>  {
                            if(filter.filter == 7 && filter.value == 32) {
                                show = false;
                            }
                        });

                        // if it is kunstbeplanting then remove selected value from location filter
                        if(!show) {
                            this.filterValues.forEach((filter, key) => {
                                if(typeof filter[0] != 'undefined' && filter[0] == 3) {
                                    delete(this.filterValues[key]);
                                }
                            });
                        }

                        return show;
                    },
                    submitStep() {
                        const that = this;

                        let data = JSON.parse(JSON.stringify(this.configurator));
                        ++data.step;

                        let formData = new FormData();
                        formData.append('configuration', this.configurator.id);

                        API.POST(window.location.origin + '/dashboard/creations', formData).then(creationResponse => {
                            API.PUT(this.submitUrl + '/' + this.configurator.id, data).then(response => {
                                Global.eventBus.$emit('loadArrangementCosts');
                                that.configurator = response.data;
                            });
                        });

                    },
                    getFilters() {
                        const that = this;

                        API.GET(this.filtersUrl).then(response => {
                            that.filters = response.data;

                            that.$nextTick(() => {
                                this.fetchProducts(true);
                            });
                        });
                    },
                    fetchProducts(showFilter = false, page = null) {
                        if (this.configurator.id) {
                            this.loadingResults = true;

                            let url = this.productsUrl;

                            if(typeof(page) != 'undefined' && !isNaN(parseInt(page))) {
                                url = Global.updateQueryStringParameter(url, 'page', page);
                            }

                            API.GET(url).then(response => {
                                this.loadingResults = false;
                                this.products = response.data;
                                this.rawResponse = response;
                                this.showFilters = showFilter;
                            });
                        }
                    },
                    fetchProductsPage(page) {
                        this.fetchProducts(false, page);
                    },
                    addProduct(product) {
                        const that = this;

                        let matchingProductIndex = this.configurator.contents.findIndex(obj => obj.id === product.id);

                        if (matchingProductIndex >= 0) {
                            ++this.configurator.contents[matchingProductIndex].quantity;
                        } else {
                            this.configurator.contents.push({
                                type: 'product',
                                id: product.id,
                                quantity: 1
                            });
                        }

                        let url = this.submitUrl + '/' + this.configurator.id;

                        API.PUT(url, this.configurator).then(response => {
                            that.configurator = response.data;

                            let gotoPage = (that.rawResponse.meta.current_page || 1);
                            that.fetchProducts(false, gotoPage);
                        });
                    },
                    convertedPrice: function (price) {
                        if (isNaN(price)) {
                            return '';
                        }

                        price = price.toFixed(2);
                        price = price.replace(".", ",");
                        price = "€ " + price;

                        return price;
                    },
                    clearFilters() {
                        this.filterValues = [];
                        this.fetchProducts(false);
                    },
                    stretchInput(element) {
                        let cs = getComputedStyle(element);
                        let offset = 0;

                        if (!element.value && element.placeholder) {
                            let empty = true;
                            element.value = element.placeholder;
                        }

                        element.style.width = "auto";

                        if (cs.boxSizing == "border-box") {
                            offset = element.offsetWidth;
                        }
                        else if (cs.boxSizing == "padding-box") {
                            offset = element.clientWidth;
                        }

                        // Safari misreports scrollWidth, so we will instead set scrollLeft to a
                        // huge number, and read that back to see what it was clipped to
                        element.scrollLeft = 1e+10;

                        var width = Math.max(element.scrollLeft + offset, element.scrollWidth - element.clientWidth) + 13;

                        element.style.width = width + "px";
                    }
                },
                created: function () {
                },
                destroyed: function () {
                }
            })
        )
    }
});
